import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import { MaintenancePeriodService } from '../../services/maintenancePeriod.service';
import { setCurrentMaintenancePeriod } from '../actions/maintenancePeriods';

@Injectable()
export class MaintenancePeriodsApi {
    constructor(
        private store: Store<AppState>,
        private maintenancePeriodService: MaintenancePeriodService,
    ) {}

    getCurrent(): Observable<any> {
        return this.maintenancePeriodService.getCurrent().pipe(
            tap((maintenancePeriod) => {
                return this.store.dispatch(setCurrentMaintenancePeriod({ maintenancePeriod }));
            }),
        );
    }
}
