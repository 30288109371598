<div class="sites-assignation-pending-wrapper">
  <div class="navigation-action-btns">
    <button [routerLink]="['/sites']" class="sites-nav-btn">{{ 'MY_INSTALLATIONS_BUTTON' | translate }}</button>
    <button [routerLink]="['/sites-pending-activation']" routerLinkActive="active"
            class="pending-activation-nav-btn">{{ 'MY_PENDING_ACTIVATION_REQUESTS_BUTTON_NAME' | translate }}
    </button>
  </div>

  @if (data$ | async; as data) {
    @if (!data.loading) {
      <div class="sites-assignation-pending-content">
        <app-attach-gateway-button />
        @if (data.siteAssignations.length > 0) {
          <section class="pending-requests-list seg-shadow"
          >
            <header class="header">
              <div class ="header-content">
                <span class="site-assignation-column site-assignation-client-column">
                  {{ 'INSTALLATION_LIST_CLIENT' | translate }}
                </span>
                <span class="site-assignation-column site-assignation-email-column">
                  {{ 'EMAIL_ADDRESS' | translate }}
                </span>
                <span class="site-assignation-column site-assignation-request-date-column">
                  {{ 'INSTALLATION_LIST_ACTIVATION_ASKING_DATE' | translate }}
                </span>
              </div>
              <div class ="header-action site-assignation-actions-column">
                <span class="site-assignation-column">
                  {{
                    'LIST_ACTIONS' | translate
                  }}
                </span>
              </div>
            </header>
            @for (siteAssignation of data.siteAssignations; track siteAssignation.gatewayId) {
              <app-site-assignation-item [siteAssignation]="siteAssignation"></app-site-assignation-item>
            }
          </section>
        } @else {
          <div class="no-site-found seg-shadow">
            {{ 'NO_PENDING_ASSIGNATION_SITES' | translate }}
          </div>
        }
      </div>
    }  @else {
      <app-loader [visible]="true"></app-loader>
    }
  }
</div>
