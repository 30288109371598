<article class="item">
  <div class="item-content">
    <div class="site-assignation-column site-assignation-client-column client">
      <app-site-icon [sitePin]="siteAssignation.gatewayId"></app-site-icon>
      <span class="box-pin">
        {{ siteAssignation.gatewayId }}
      </span>
    </div>
    <span class="site-assignation-column site-assignation-email-column">
      @for (mailPart of getMailParts(siteAssignation.endUserEmail); track mailPart) {
        <span>
          {{ mailPart }}
        </span>
      }
    </span>
    <span class="site-assignation-column site-assignation-request-date-column">
      {{ siteAssignation.requestTimestamp | format: { type: 'shortDate' } }}
    </span>
  </div>
  <div class="item-actions">
    <span class="site-assignation-column site-assignation-actions-column">
      <app-resend-activation-button [gatewayId]="siteAssignation.gatewayId" [enduserEmail]="siteAssignation.endUserEmail" />
      <app-menu>
        <app-delete-activation-request-button [gatewayId]="siteAssignation.gatewayId" />
      </app-menu>
    </span>
  </div>
</article>
