import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MaintenancePeriod } from '../../../../admin/components/maintenance-period/model/maintenance-period';
import { toHugeDate, toTime } from '../../../../utils/date';

interface ModalMaintenancePeriodData {
    maintenancePeriod: MaintenancePeriod;
}

@Component({
    selector: 'app-modal-maintenance-period',
    templateUrl: './modal-maintenance-period.component.html',
    styleUrls: ['./modal-maintenance-period.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatButton, MatDialogClose, TranslateModule],
})
export class ModalMaintenancePeriodComponent implements OnDestroy {
    @Input() maintenancePeriod: MaintenancePeriod;

    private unsubscribe$: Subject<void> = new Subject();

    content = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalMaintenancePeriodData,
        private translateService: TranslateService,
    ) {
        this.content = this.translateService.instant('MAINTENANCE_PERIOD_MODAL_CONTENT', {
            date: '\n' + toHugeDate(this.data.maintenancePeriod.startDate).toUpperCase(),
            startTime: toTime(this.data.maintenancePeriod.startDate * 1000),
            endTime: toTime(this.data.maintenancePeriod.endDate * 1000),
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
