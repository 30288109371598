import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import { SitesList } from '../../models/sites';
import { SitesListFilters } from '../../models/SitesListFilters';
import { HttpSitesGateway } from '../../services/http-sites-gateway.service';
import { SitesActions } from '../actions/sites';
import { updateSitesListMaxCount } from '../actions/ui';

@Injectable()
export class SitesApi {
    constructor(
        private store: Store<AppState>,
        private sitesService: HttpSitesGateway,
    ) {}

    loadSites(filters: SitesListFilters): Observable<SitesList> {
        const retreivedSites = this.sitesService.getSites(filters.offset, filters.limit, filters.keywords);
        const loadSiteToStore = (sitesList: SitesList) =>
            this.store.dispatch(SitesActions.sitesLoaded({ sites: sitesList.sites }));
        const loadSitesListMaxcount = (sitesList: SitesList) =>
            this.store.dispatch(updateSitesListMaxCount({ maxCount: sitesList.count }));
        return retreivedSites.pipe(tap(loadSiteToStore), tap(loadSitesListMaxcount), share());
    }

    loadFavorites(): Observable<any> {
        // Max 5 favorite, so get 5 max
        return this.sitesService.getSites(0, 5, '').pipe(
            tap((sitesList: SitesList) =>
                this.store.dispatch(
                    SitesActions.favoritesLoaded({
                        favorites: sitesList.sites.filter((s) => s.favorite),
                    }),
                ),
            ),
        );
    }
}
