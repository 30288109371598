import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AutoDestroy } from '../../../shared/annotations/autodestroy';
import { SharedModule } from '../../../shared/shared.module';
import { AppState } from '../../../shared/store/app-state';
import type { SiteAssignation } from '../../models/SiteAssignation';
import { loadPendingSiteAssignations } from '../../store/actions/site-assignation';
import { SiteAssignationApi } from '../../store/api/site-assignation-api';
import { getSiteAssignations } from '../../store/selectors/site-assignation';
import { AttachGatewayButtonComponent } from '../attach-gateway-button/attach-gateway-button.component';
import { SiteAssignationItemComponent } from '../site-assignation-item/site-assignation-item.component';

interface SiteAssignationData {
    loading: boolean;
    siteAssignations: SiteAssignation[];
}

@Component({
    standalone: true,
    selector: 'app-site-assignation-list',
    templateUrl: './site-assignation-list.component.html',
    styleUrls: ['./site-assignation-list.component.scss', './../../site-assignation.scss'],
    imports: [TranslateModule, RouterLink, SharedModule, AttachGatewayButtonComponent, SiteAssignationItemComponent],
})
export class SiteAssignationListComponent implements OnInit {
    readonly assignationSites$: Observable<SiteAssignation[]>;
    @AutoDestroy componentDestroyed$ = new Subject<void>();
    loading$ = new BehaviorSubject(true);
    data$: Observable<SiteAssignationData>;

    constructor(
        private sitesApi: SiteAssignationApi,
        private actions$: Actions,
        private store: Store<AppState>,
    ) {
        this.assignationSites$ = this.store.select(getSiteAssignations);
    }

    ngOnInit(): void {
        this.sitesApi.getAllSiteAssignationPendingRequest().pipe(takeUntil(this.componentDestroyed$)).subscribe();

        this.actions$
            .pipe(takeUntil(this.componentDestroyed$), ofType(loadPendingSiteAssignations))
            .subscribe(() => this.loading$.next(false));

        this.data$ = combineLatest([this.loading$, this.assignationSites$]).pipe(
            takeUntil(this.componentDestroyed$),
            map(([loading, siteAssignations]) => {
                return {
                    loading,
                    siteAssignations,
                };
            }),
        );
    }
}
