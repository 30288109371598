import { Component, Input } from '@angular/core';
import { MatMenuItem } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, filter, take, takeUntil } from 'rxjs/operators';
import { AutoDestroy } from '../../../shared/annotations/autodestroy';
import { ErrorService } from '../../../shared/services/error.service';
import { ModalService } from '../../../shared/services/modal.service';
import { SiteAssignationApi } from '../../store/api/site-assignation-api';

@Component({
    selector: 'app-delete-activation-request-button',
    standalone: true,
    imports: [MatMenuItem, TranslateModule],
    templateUrl: './delete-activation-request-button.component.html',
    styleUrl: './delete-activation-request-button.component.scss',
})
export class DeleteActivationRequestButtonComponent {
    @Input() gatewayId: string;
    @AutoDestroy componentDestroyed$ = new Subject<void>();

    constructor(
        private modalService: ModalService,
        private sitesApi: SiteAssignationApi,
        private errorService: ErrorService,
    ) {}

    onDeleteActivationRequestClick(): void {
        const deletionModal = this.modalService.showConfirmDeleteModal('REQUEST_WARNING_BEFORE_DELETE');

        deletionModal
            .afterClosed()
            .pipe(take(1), filter(Boolean))
            .subscribe(() => this.deletePendingRequest(this.gatewayId));
    }

    deletePendingRequest(gatewayId: string): void {
        this.sitesApi
            .deleteSiteAssignationRequest(gatewayId)
            .pipe(
                take(1),
                takeUntil(this.componentDestroyed$),
                catchError((err) => {
                    this.errorService.showToasterError('ERROR_WHILE_SENDING_DELETE_ASSIGNATION_REQUEST');

                    return throwError(err);
                }),
            )
            .subscribe();
    }
}
