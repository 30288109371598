import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    tap,
    throttleTime,
    timeout,
} from 'rxjs/operators';
import { AlertSeverity } from '../../alerts/models/Alert';
import { getAlertsSeverityBySites } from '../../alerts/store/selectors/alerts';
import { ModalAttachGatewayComponent } from '../../shared/components/modal/modal-attach-gateway/modal-attach-gateway.component';
import { User } from '../../shared/models/User';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { ErrorService } from '../../shared/services/error.service';
import { AppState } from '../../shared/store/app-state';
import { getUserLoginState } from '../../shared/store/selectors/login';
import { getUserEnvLoadingState } from '../../shared/store/selectors/user-env';
import { Site, type SiteId } from '../../site-detail/models/Site';
import { getUISiteFilters, getUISiteMaxCount } from '../../site-detail/store/selectors/ui';
import { LoadSiteRequestStatus, SitesListFilters } from '../models/SitesListFilters';
import { SitesActions } from '../store/actions/sites';
import { updateSitesListFilters } from '../store/actions/ui';
import { SitesApi } from '../store/api/sites';
import { getFavorites, getSitesList } from '../store/selectors/site-list';
import { SiteItemComponent } from './site-item/site-item.component';

@Component({
    selector: 'app-site-list',
    templateUrl: './site-list.component.html',
    styleUrls: ['./site-list.component.scss', '../site-list.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLinkActive,
        RouterLink,
        NgClass,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        SiteItemComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class SiteListComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly sites$: Observable<Site[]>;
    sites: Site[] = [];

    readonly favorites$: Observable<SiteId[]>;

    readonly userEnvLoading$: Observable<boolean>;
    userEnvLoading = true;

    readonly userLogin$: Observable<User>;
    userLogin: User;

    readonly alertsSeverityBySites$: Observable<Record<SiteId, AlertSeverity>>;

    readonly sitesListFilters$: Observable<SitesListFilters>;
    sitesListFilters: SitesListFilters;

    readonly sitesListMaxCount$: Observable<number>;
    sitesListMaxCount: number;

    maxFavoriteReached = true;

    readonly itemsPerPage = 10;
    readonly maxFavorite = 5;

    alertsSeverityBySites: Record<SiteId, AlertSeverity>;
    subs = [];
    siteLoading: boolean;
    searchLabel$: Observable<string>;
    scrollmessage: boolean;

    private searchUpdated: Subject<string> = new Subject();
    @ViewChild('scroller', { static: true }) scroller: CdkVirtualScrollViewport;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private sitesApi: SitesApi,
        private ref: ChangeDetectorRef,
        private errorService: ErrorService,
        private store: Store<AppState>,
        private analyticsService: AnalyticsService,
    ) {
        this.userLogin$ = this.store.select(getUserLoginState);
        this.userEnvLoading$ = this.store.select(getUserEnvLoadingState);
        this.sites$ = this.store.select(getSitesList);
        this.favorites$ = this.store.select(getFavorites);
        this.alertsSeverityBySites$ = this.store.select(getAlertsSeverityBySites);
        this.sitesListFilters$ = this.store.select(getUISiteFilters);
        this.sitesListMaxCount$ = this.store.select(getUISiteMaxCount);

        this.searchUpdated
            .asObservable()
            .pipe(debounceTime(600), distinctUntilChanged())
            .subscribe((searchedValue) => {
                // Check if value is  different from previoux search
                if (searchedValue.trim() !== this.sitesListFilters.keywords) {
                    if (this.sitesListFilters.keywords.trim() === '') {
                        this.analyticsService.searchBoxFromInstallationScreen();
                    }

                    this.store.dispatch(SitesActions.sitesListReset());
                    this.store.dispatch(
                        updateSitesListFilters({
                            filters: {
                                offset: 1,
                                limit: this.itemsPerPage,
                                keywords: searchedValue,
                                status: LoadSiteRequestStatus.EXECUTING,
                            },
                        }),
                    );
                }
            });
    }

    ngOnInit() {
        this.siteLoading = true;
        this.scrollmessage = false;
        this.searchLabel$ = this.translateService.stream('SITE_LIST_SEARCH');

        this.subs.push(this.sitesApi.loadFavorites().subscribe());

        this.subs.push(
            this.userEnvLoading$.subscribe((loading) => {
                this.userEnvLoading = loading;
                if (
                    !this.userEnvLoading &&
                    this.sitesListFilters &&
                    this.sitesListFilters.status === LoadSiteRequestStatus.NOT_DONE
                ) {
                    this.store.dispatch(
                        updateSitesListFilters({
                            filters: {
                                offset: 1,
                                limit: this.itemsPerPage,
                                keywords: '',
                                status: LoadSiteRequestStatus.EXECUTING,
                            },
                        }),
                    );
                }
            }),
        );

        this.subs.push(
            this.userLogin$.subscribe((userLogin) => {
                this.userLogin = userLogin;
                this.ref.detectChanges();
            }),
        );

        this.subs.push(
            this.sites$.subscribe((sites) => {
                this.sites = sites;
                this.ref.detectChanges();
            }),
        );

        this.subs.push(
            this.favorites$.subscribe((favorites) => {
                this.maxFavoriteReached = favorites.length >= this.maxFavorite;
                this.ref.detectChanges();
            }),
        );

        this.subs.push(
            this.alertsSeverityBySites$.subscribe((alertsSeverityBySites) => {
                this.alertsSeverityBySites = alertsSeverityBySites;
                this.ref.detectChanges();
            }),
        );

        this.subs.push(
            this.sitesListFilters$.subscribe((sitesListFilters) => {
                this.sitesListFilters = sitesListFilters;
                if (this.sitesListFilters) {
                    const endLoading = () => {
                        this.scrollmessage = false;
                        this.siteLoading = false;
                        this.ref.detectChanges();

                        if (
                            this.scroller.measureRenderedContentSize() < this.scroller.getViewportSize() &&
                            this.scroller.getDataLength() < this.sitesListMaxCount
                        ) {
                            this.fetchMore();
                        }
                    };
                    switch (this.sitesListFilters.status) {
                        case LoadSiteRequestStatus.EXECUTING:
                            this.scrollmessage = true;
                            this.siteLoading = true;
                        // break is missing but this is intended...Sopra's style....
                        // eslint-disable-next-line no-fallthrough
                        case LoadSiteRequestStatus.NOT_DONE:
                            if (!this.userEnvLoading) {
                                // Load sites with filters
                                // this.siteLoading = true;
                                this.scrollmessage = true;
                                this.sitesApi
                                    .loadSites(this.sitesListFilters)
                                    .pipe(
                                        tap(() =>
                                            this.store.dispatch(
                                                updateSitesListFilters({
                                                    filters: {
                                                        offset: this.sitesListFilters.offset,
                                                        limit: this.sitesListFilters.limit,
                                                        keywords: this.sitesListFilters.keywords,
                                                        status: LoadSiteRequestStatus.DONE,
                                                    },
                                                }),
                                            ),
                                        ),
                                        timeout(30 * 1000), // how long before stop waiting
                                        catchError((err) => {
                                            this.errorService.showToasterError(
                                                'ERROR_SITES_TAKES_TO_LONG_TO_LOAD_OR_UNAVAILABLE',
                                                {
                                                    duration: 10 * 1000, // how long do we want to display the error toaster
                                                },
                                            );
                                            return of(err);
                                        }),
                                    )
                                    .subscribe({
                                        complete: endLoading, // whatever the result, stop loading animation
                                    });
                            }
                            break;
                        case LoadSiteRequestStatus.DONE:
                            this.siteLoading = false;
                            this.scrollmessage = false;
                            break;
                    }
                }
                this.ref.detectChanges();
            }),
        );

        this.subs.push(
            this.sitesListMaxCount$.subscribe((sitesListMaxCount) => {
                this.sitesListMaxCount = sitesListMaxCount;
                this.ref.detectChanges();
            }),
        );
    }

    ngOnDestroy() {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    ngAfterViewInit(): void {
        this.scroller
            .elementScrolled()
            .pipe(
                map(() => this.scroller.measureScrollOffset('bottom')),
                pairwise(),
                filter(([y1, y2]) => y2 < y1 && y2 < 140),
                throttleTime(200),
            )
            .subscribe(() => {
                this.fetchMore();
            });
    }

    fetchMore(): void {
        this.scrollmessage = true;
        this.ref.detectChanges();

        this.store.dispatch(
            updateSitesListFilters({
                filters: {
                    offset: this.sitesListFilters.offset + this.sitesListFilters.limit,
                    limit: this.sitesListFilters.limit,
                    keywords: this.sitesListFilters.keywords,
                    status: LoadSiteRequestStatus.EXECUTING,
                },
            }),
        );
    }

    onSearch(evt) {
        const searchedValue = evt.target.value;
        this.searchUpdated.next(searchedValue);
    }

    navigateToSitesPending() {
        this.router.navigate(['/sites-pending-activation']).then(() => {
            return this.dialog.open(ModalAttachGatewayComponent, {
                data: {
                    enduserEmail: '',
                    gatewayId: '',
                    readonly: false,
                },
            });
        });
    }
}
