import { Component, Input } from '@angular/core';
import { MenuComponent } from '../../../shared/components/menu/menu.component';
import { SharedModule } from '../../../shared/shared.module';
import type { SiteAssignation } from '../../models/SiteAssignation';
import { DeleteActivationRequestButtonComponent } from '../delete-activation-request-button/delete-activation-request-button.component';
import { ResendActivationButtonComponent } from '../resend-activation-button/resend-activation-button.component';

@Component({
    standalone: true,
    selector: 'app-site-assignation-item',
    templateUrl: './site-assignation-item.component.html',
    styleUrls: ['./site-assignation-item.component.scss', './../../site-assignation.scss'],
    imports: [SharedModule, MenuComponent, ResendActivationButtonComponent, DeleteActivationRequestButtonComponent],
})
export class SiteAssignationItemComponent {
    @Input() siteAssignation: SiteAssignation;

    getMailParts(mail: string): string[] {
        const [localPart, domainPart] = mail.split('@');
        return [localPart, '@' + domainPart];
    }
}
