<div class="tooltip-wrapper" [matTooltip]="tooltip | translate">
  <a [id]="site.id"
     [routerLink]="['/site', site.id]"
     routerLinkActive="active"
     [ngClass]="{'site-item': true, 'disabled-site-item': !site.isServego || site?.isToolSite}"
     (click)="onFirstConnection(site.id)">
    <div class="site-link-content">
      <span class="sites-column sites-client-name-column">
        @if (site.owner && !site?.isToolSite) {
          <app-site-icon [sitePin]="site.gatewayId" [nbBoxes]="site.setupGateways.length"></app-site-icon>
          <div>{{ site.owner.firstName }} {{ site.owner.lastName }}</div>
        } @else if (site?.isToolSite) {
          <app-site-icon [sitePin]="site.gatewayId"></app-site-icon>
          <div>{{ site?.toolsite_info.label }}</div>
        } @else {
          <span class="minus">&#x2212;</span>
        }
      </span>
      <span class="sites-column sites-date-column">
        @if (site.activateDate && !site.isToolSite) {
          <span>{{ date }}</span>
        } @else {
          <span class="minus">&#x2212;</span>
        }
      </span>
      <span class="sites-column sites-severity-column">
        @if (alertsSeverity === null) {
          <span class="minus">&#x2212;</span>
        } @else {
          <app-alert-severity [severity]="alertsSeverity" />
        }
      </span>
    </div>
    <div class="sites-column sites-actions-column">
      <span class="favorite">
        <app-site-favorite [site]="site"
                           [maxFavoriteReached]="maxFavoriteReached"
        ></app-site-favorite>
      </span>
    </div>
  </a>
</div>
