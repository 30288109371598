import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { ApiSite, buildSiteFromApiResult } from '../../utils/buildSiteFromApiResult';
import { SitesList } from '../models/sites';
import { SitesGateway } from './SitesGateway';

@Injectable()
export class HttpSitesGateway implements SitesGateway {
    constructor(private httpService: HttpService) {}

    getSites(offset = 1, limit = 10, keywords = ''): Observable<SitesList> {
        const k = keywords !== '' ? `&keywords=${keywords}` : '';
        return this.httpService.get(`/site?offset=${offset}&limit=${limit}${k}`).pipe(
            map((apiResults: { count: number; sites: ApiSite[] }) => ({
                count: apiResults.count,
                sites: apiResults.sites.map(buildSiteFromApiResult),
            })),
        );
    }

    addFavorite(siteId: string): Observable<any> {
        return this.httpService.post(`/site/${siteId}/favorite`, {});
    }

    removeFavorite(siteId: string): Observable<any> {
        return this.httpService.delete(`/site/${siteId}/favorite`);
    }
}
