<div class="site-list-wrapper">
  <div class="buttons-wrapper">
    <button>{{ 'MY_INSTALLATIONS_BUTTON' | translate }}</button>
    <button [routerLink]="['/sites-pending-activation']"
      routerLinkActive="active">{{ 'MY_PENDING_ACTIVATION_REQUESTS_BUTTON_NAME' | translate }}
    </button>
  </div>

  <div class="search-input-wrapper">
    <input class="seg-shadow search"
      [ngClass]="{ 'search-background': !sitesListFilters || !sitesListFilters.keywords}"
      [placeholder]="searchLabel$ | async"
      [disabled]="sites.length === 0 && (!sitesListFilters || !sitesListFilters.keywords)"
      type="search"
      (keyup)="onSearch($event)"
      (search)="onSearch($event)"
      [value]="sitesListFilters && sitesListFilters.keywords ? sitesListFilters.keywords : ''">
  </div>

  <section class="scroller sites-wrapper seg-shadow">
    @if (sites.length > 0 || sitesListFilters && sitesListFilters.keywords) {
      <header class="site-list-header">
        <div class ="site-list-header-content">
          <span class="sites-column sites-client-name-column">
            {{ 'INSTALLATION_LIST_CLIENT' | translate }}
          </span>
          <span class="sites-column sites-date-column">
            {{ 'INSTALLATION_LIST_ACTIVATION_DATE' | translate }}
          </span>
          <span class="sites-column sites-severity-column">
            {{ 'INSTALLATION_LIST_ALERTS' | translate }}
          </span>
        </div>
        <div class="site-list-header-action">
          <span class="sites-column sites-actions-column">
             {{ 'LIST_ACTIONS' | translate }}
          </span>
        </div>
      </header>
    }

    <cdk-virtual-scroll-viewport #scroller [itemSize]="70">
      <article *cdkVirtualFor="let site of sites" class="list-item">
        <app-site-item [site]="site"
          [userLogin]="userLogin"
          [alertsSeverity]="alertsSeverityBySites[site.id]"
          [maxFavoriteReached]="maxFavoriteReached"
        ></app-site-item>
      </article>
      @if (scrollmessage) {
        <div class="loading-message">{{ 'LOADING' | translate }}</div>
      }

      @if (sites?.length === 0 && !scrollmessage) {
        @if (sitesListFilters && sitesListFilters.keywords.length > 0) {
          <div
            class="no-site-found-search">
            <div>
              <h4>{{ 'NO_SITE_FOUND' | translate }}</h4>
              <span>{{ 'CRITERIA_SEARCH_SITE'| translate }}</span>
              <ul>
                <li class="no-site-criteria">
                  {{ 'CRITERIA_SEARCH_FIRSTNAME'| translate }}
                </li>
                <li class="no-site-criteria">
                  {{ 'CRITERIA_SEARCH_LASTNAME'| translate }}
                </li>
                <li class="no-site-criteria">
                  {{ 'CRITERIA_SEARCH_POSTALCODE'| translate }}
                </li>
                <li class="no-site-criteria">
                  {{ 'CRITERIA_SEARCH_CITY'| translate }}
                </li>
                <li class="no-site-criteria">
                  {{ 'CRITERIA_SEARCH_MAIL'| translate }}
                </li>
                <li class="no-site-criteria">
                  {{ 'CRITERIA_SEARCH_PIN_CODE'| translate }}
                </li>
              </ul>
            </div>
          </div>
        } @else if (!siteLoading) {
          <div class="no-connected-sites-content">
            <p class="main-text">{{ 'NO_SITES_MAIN_TEXT' | translate }}</p>
            <div class="no-connected-sites-message-content">
              <p>{{ 'NO_SITE_MESSAGE_1' | translate }}</p>
              <p>{{ 'NO_SITE_MESSAGE_2' | translate }}</p>
              <p>{{ 'NO_SITE_MESSAGE_3' | translate }}</p>
            </div>
            <a class="active-installation-btn" (click)="navigateToSitesPending()">{{ 'NO_SITES_BUTTON' | translate }}</a>
          </div>
        }
      }
    </cdk-virtual-scroll-viewport>
  </section>
</div>
