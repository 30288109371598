import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import type { GatewayActivationOption } from '../../../shared/components/modal/modal-attach-gateway/GatewayActivationOption';
import { ModalAttachGatewayComponent } from '../../../shared/components/modal/modal-attach-gateway/modal-attach-gateway.component';

@Component({
    selector: 'app-resend-activation-button',
    standalone: true,
    imports: [TranslateModule, MatTooltipModule, MatIconButton],
    templateUrl: './resend-activation-button.component.html',
    styleUrl: './resend-activation-button.component.scss',
})
export class ResendActivationButtonComponent implements AfterViewInit {
    @ViewChild('resendActivationId', { static: false }) resendActivationId: ElementRef;
    @Input() enduserEmail: string;
    @Input() gatewayId: string;

    isTextOverflow: boolean = false;

    constructor(private dialog: MatDialog) {}

    ngAfterViewInit() {
        this.checkTextOverflow();
    }

    checkTextOverflow() {
        const element = this.resendActivationId.nativeElement;

        this.isTextOverflow = element.scrollWidth > element.clientWidth;
    }

    onResendActivationEmailClick(): void {
        const activationOptions: GatewayActivationOption = {
            enduserEmail: this.enduserEmail,
            gatewayId: this.gatewayId,
            readonly: true,
        };

        this.dialog.open(ModalAttachGatewayComponent, {
            data: activationOptions,
        });
    }
}
