import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import { AlertService } from '../../services/alert.service';
import { AlertsActions } from '../actions/alerts';

@Injectable()
export class AlertsApi {
    constructor(
        private store: Store<AppState>,
        private alertsService: AlertService,
    ) {}

    loadAlertsEnvironment(): Observable<any> {
        return this.alertsService
            .getAlerts()
            .pipe(tap((alerts) => this.store.dispatch(AlertsActions.alertsLoaded({ alerts }))));
    }
}
