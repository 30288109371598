import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ErrorService } from '../../../shared/services/error.service';
import { AppState } from '../../../shared/store/app-state';
import { Site } from '../../../site-detail/models/Site';
import { HttpSitesGateway } from '../../services/http-sites-gateway.service';
import { SitesActions } from '../../store/actions/sites';

@Component({
    selector: 'app-site-favorite',
    templateUrl: './site-favorite.component.html',
    styleUrls: ['./site-favorite.component.scss'],
    standalone: true,
    imports: [NgClass, MatTooltip, TranslateModule],
})
export class SiteFavoriteComponent {
    @Input()
    site: Site;
    @Input()
    maxFavoriteReached = false;

    loading = false;

    constructor(
        private sitesService: HttpSitesGateway,
        private errorService: ErrorService,
        private store: Store<AppState>,
        private changeDetector: ChangeDetectorRef,
    ) {}

    onAddFavorite($event): void {
        this.process($event, 'addFavorite');
    }

    onRemoveFavorite($event): void {
        this.process($event, 'removeFavorite');
    }

    private supportedErrors = ['wrong_customerId', 'favorite_limit', 'not_supported', 'unknown_gateway'];

    private byPassedErrors = ['already_favorite', 'not_favorite'];

    private process($event, fn: string): void {
        $event.stopPropagation();
        $event.preventDefault();
        if (this.loading) {
            return;
        }

        this.loading = true;

        this.sitesService[fn](this.site.id)
            .pipe(
                tap(() => {
                    this.dispatchAction(fn);
                }),
                catchError((err) => {
                    const errorMessage = err.error.message;

                    if (this.byPassedErrors.includes(errorMessage)) {
                        this.dispatchAction(fn);
                        return of();
                    }

                    const toasterMessage = this.supportedErrors.includes(errorMessage)
                        ? `ERROR_FAVORITE_${errorMessage.toUpperCase()}`
                        : `ERROR_FAVORITE_UNKNOWN`;
                    this.errorService.showToasterError(toasterMessage);

                    return throwError(err);
                }),
                finalize(() => {
                    this.loading = false;
                    this.changeDetector.detectChanges();
                }),
            )
            .subscribe();
    }

    private dispatchAction(fn: string): void {
        const actions = {
            addFavorite: SitesActions.siteAddedAsFavorite,
            removeFavorite: SitesActions.siteRemovedFromFavorites,
        };
        this.store.dispatch(actions[fn]({ siteId: this.site.id }));
    }
}
