export type SiteId = string;

export type SetupId = string;

export interface Owner {
    firstName: string;
    lastName: string;
    email: string;
    tel: string;
    adresse_1: string;
    adresse_2: string;
    city: string;
    zipcode: string;
    country: string;
}

export interface Site {
    id: SiteId;
    setupId: SetupId;
    gatewayId: string;
    address: Address;
    setupGateways?: string[];
    favorite: boolean;
    isServego: boolean;
    isServegoAsking?: boolean;
    activateDate: number;
    servegoActivateDate?: number;
    activation_status: ACTIVATION_STATUS | string;
    boxtype?: string;
    isToolSite?: boolean;
    toolsite_info?: ToolSiteInfo;
    owner: Owner;
}

export interface ToolSiteInfo {
    label?: string;
    qrCode?: string;
}

export enum ACTIVATION_STATUS {
    NEW = 'NEW',
    DONE = 'DONE',
    DONE_NEEDACTIVATION = 'DONE_NEEDACTIVATION',
    TODO_PREPARED = 'TODO_PREPARED',
    TODO_CONFIGURED = 'TODO_CONFIGURED',
}

export interface Address {
    addressLine1?: string;
    addressLine2?: string;
    country?: string;
    city?: string;
    postalCode?: string;
}

export interface SiteUserCreateRequest {
    id_site: string;
    id_user: string;
    date?: string;
    mail: string;
}

export interface OnBoardingUpdateRequest {
    skipOnboarding: boolean;
}

export interface OnBoardingReponse {
    onboarding: boolean;
}
