import { createReducer, on } from '@ngrx/store';
import { LoadSiteRequestStatus, SitesListFilters } from '../../models/SitesListFilters';
import { updateSitesListFilters, updateSitesListMaxCount } from '../actions/ui';

export interface UISiteListState {
    filters: SitesListFilters;
    maxCount: number;
}

export const UI_SITE_LIST_INITIAL_STATE: UISiteListState = {
    filters: {
        limit: 10,
        offset: 0,
        keywords: '',
        status: LoadSiteRequestStatus.NOT_DONE,
    },
    maxCount: 0,
};

export default createReducer(
    UI_SITE_LIST_INITIAL_STATE,

    on(
        updateSitesListFilters,
        (state, { filters }): UISiteListState => ({
            ...state,
            filters,
        }),
    ),

    on(
        updateSitesListMaxCount,
        (state, { maxCount }): UISiteListState => ({
            ...state,
            maxCount,
        }),
    ),
);
