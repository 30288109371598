import { createAction, props } from '@ngrx/store';
import { UIActions } from '../../../site-detail/store/actions/ui';
import { LoadSiteRequestStatus } from '../../models/SitesListFilters';

export const updateSitesListFilters = createAction(
    UIActions.UPDATE_SITES_LIST_FILTERS,
    props<{
        filters: {
            offset: number;
            limit: number;
            keywords: string;
            status: LoadSiteRequestStatus;
        };
    }>(),
);

export const updateSitesListMaxCount = createAction(
    UIActions.UPDATE_SITES_LIST_MAX_COUNT,
    props<{ maxCount: number }>(),
);
