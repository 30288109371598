import { createAction, props } from '@ngrx/store';
import type { PendingInterventionCreateRequest } from '../../../interventions/models/PendingInterventionCreateRequest';
import { Execution } from '../../models/Execution';

export const INITIAL_REFRESH_SECTION = 'initial-refresh-section';

export class UIActions {
    static readonly SET_SELECTED_SITE = 'SET_SELECTED_SITE';

    static readonly ADD_DEVICE_STATE_TO_SYNC = 'ADD_DEVICE_STATE_TO_SYNC';
    static readonly UPDATE_DEVICE_STATE_EXECUTION_STATUS = 'UPDATE_DEVICE_STATE_EXECUTION_STATUS';
    static readonly UPDATE_DEVICE_STATE_EXECUTION_STATUS_BY_ID = 'UPDATE_DEVICE_STATE_EXECUTION_STATUS_BY_ID';
    static readonly REMOVE_DEVICE_STATES_TO_SYNC = 'REMOVE_DEVICE_STATES_TO_SYNC';
    static readonly ADD_DEVICE_SECTION_TO_SYNC = 'ADD_DEVICE_SECTION_TO_SYNC';
    static readonly REMOVE_DEVICE_SECTION_TO_SYNC = 'REMOVE_DEVICE_SECTION_TO_SYNC';
    static readonly UPDATE_EXECUTION_STATUS = 'UPDATE_EXECUTION_STATUS';

    static readonly ADD_TOASTER_MESSAGE = 'ADD_TOASTER_MESSAGE';
    static readonly REMOVE_TOASTER_MESSAGE = 'REMOVE_TOASTER_MESSAGE';

    static readonly UPDATE_DEVICE_DEFINITION_UI = 'UPDATE_DEVICE_DEFINITION_UI';
    static readonly UPDATE_SITES_LIST_FILTERS = 'UPDATE_SITES_LIST_FILTERS';
    static readonly UPDATE_SITES_LIST_MAX_COUNT = 'UPDATE_SITES_LIST_MAX_COUNT';

    static readonly SET_LOADING_SITE_CONTENT_MESSAGE = 'SET_LOADING_SITE_CONTENT_MESSAGE';
    static readonly ADD_MANUAL_EXECUTION = 'ADD_MANUAL_EXECUTION';
    static readonly UPDATE_MANUAL_EXECUTION = 'UPDATE_MANUAL_EXECUTION';
    static readonly REMOVE_MANUAL_EXECUTION = 'REMOVE_MANUAL_EXECUTION';

    static readonly ADD_INTERVENTION_PENDING_ACTION = 'ADD_INTERVENTION_PENDING_ACTION';
    static readonly REMOVE_INTERVENTION_PENDING_ACTION = 'REMOVE_INTERVENTION_PENDING_ACTION';
}

export const addManualExecution = createAction(UIActions.ADD_MANUAL_EXECUTION, props<{ exec: Execution }>());

export const updateManualExecution = createAction(UIActions.UPDATE_MANUAL_EXECUTION, props<{ exec: Execution }>());

export const removeManualExecution = createAction(UIActions.REMOVE_MANUAL_EXECUTION, props<{ exec: Execution }>());

export const setSelectedSite = createAction(UIActions.SET_SELECTED_SITE, props<{ siteID: string }>());

export const addDeviceStateToSync = createAction(
    UIActions.ADD_DEVICE_STATE_TO_SYNC,
    props<{
        siteId: string;
        deviceId: string;
        state: any;
    }>(),
);

export const removeDeviceStateToSync = createAction(UIActions.REMOVE_DEVICE_STATES_TO_SYNC, props<{ jobId: string }>());

export const updateDeviceStateToSyncExecutionStatus = createAction(
    UIActions.UPDATE_DEVICE_STATE_EXECUTION_STATUS,
    props<{
        siteId: string;
        execution: Execution;
    }>(),
);

export const updateDeviceStateToSyncExecutionStatusById = createAction(
    UIActions.UPDATE_DEVICE_STATE_EXECUTION_STATUS_BY_ID,
    props<{
        execution: Execution;
    }>(),
);

export const addDeviceSectionToSync = createAction(
    UIActions.ADD_DEVICE_SECTION_TO_SYNC,
    props<{
        siteId: string;
        deviceId: string;
        sectionTitle: any;
        executions: Execution[];
    }>(),
);

export const clearDeviceSectionSyncState = createAction(
    UIActions.REMOVE_DEVICE_SECTION_TO_SYNC,
    props<{
        siteId: string;
        sectionTitle: any;
        deviceId: string;
    }>(),
);

export const updateExecutionStatus = createAction(UIActions.UPDATE_EXECUTION_STATUS, props<{ execution: Execution }>());

export const removeToasterMessage = createAction(UIActions.REMOVE_TOASTER_MESSAGE, props<{ id: string }>());

export const updateDeviceDefinitionUI = createAction(
    UIActions.UPDATE_DEVICE_DEFINITION_UI,
    props<{
        deviceType: string;
        definitionUI: any;
    }>(),
);

export const setLoadingSiteContentMessage = createAction(
    UIActions.SET_LOADING_SITE_CONTENT_MESSAGE,
    props<{ loadingMessage: string }>(),
);

export const addPendingInterventionAction = createAction(
    UIActions.ADD_INTERVENTION_PENDING_ACTION,
    props<{ interventionAction: PendingInterventionCreateRequest }>(),
);

export const removePendingInterventionAction = createAction(
    UIActions.REMOVE_INTERVENTION_PENDING_ACTION,
    props<{ interventionAction: PendingInterventionCreateRequest }>(),
);
