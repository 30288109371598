@if (!site.isToolSite) {
  @if (site.favorite) {
    <img
      class="big-icon remove-from-favorite"
      [ngClass]="{ 'loading': loading }"
      src="assets/img/favorite.svg"
      [matTooltip]="'REMOVE_FROM_FAVORITE' | translate"
      (click)="onRemoveFavorite($event)"
    >
  } @else if (!maxFavoriteReached) {
    <img
      class="big-icon add-to-favorite"
      [ngClass]="{ 'loading': loading }"
      src="assets/img/favoritable.svg"
      [matTooltip]="'ADD_TO_FAVORITE' | translate"
      (click)="onAddFavorite($event)"
    >
  } @else {
    <img
      class="big-icon max-favorite-reached"
      src="assets/img/favoritable.svg"
      [matTooltip]="'MAX_FAVORITE_REACHED' | translate"
      (click)="$event.preventDefault(); $event.stopPropagation()"
    >
  }
}
