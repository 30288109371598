<div class="seg-modal">
    <!-- backrop true  -->

    <div class="seg-modal-header">
        <h4 class="seg-modal-title">
            {{ 'MAINTENANCE_PERIOD_MODAL_TITLE' | translate }}
        </h4>
    </div>

    <mat-dialog-content class="seg-modal-body">
        <span class="seg-text">{{ content }}</span>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            id="btn-validate"
            class="seg-btn seg-bg-color-primary text-lowercase"
            mat-button
            mat-dialog-close
        >
            {{ 'MAINTENANCE_PERIOD_MODAL_BUTTON' | translate }}
        </button>
    </mat-dialog-actions>
</div>

