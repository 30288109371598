export interface SitesListFilters {
    limit: number;
    offset: number;
    keywords: string;
    status: LoadSiteRequestStatus;
}

export enum LoadSiteRequestStatus {
    DONE = 'DONE',
    NOT_DONE = 'NOT_DONE',
    EXECUTING = 'EXECUTING',
}
