import { createSelector } from '@ngrx/store';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { getSitesState, SitesState } from '../reducers/sites';

export const getFavorites = createSelector(getSitesState, (state: SitesState) => state?.favorites);

export const getSiteIdList = createSelector(getSitesState, getFavorites, (state: SitesState, favorites: SiteId[]) => {
    const siteIdList = state?.list || [];

    const sites = siteIdList.map((id) => state.sites[id]);

    const sortedSites = sites.sort((a: Site, b: Site) => {
        const isAFavorite = favorites.includes(a.id);
        const isBFavorite = favorites.includes(b.id);

        if (isAFavorite === isBFavorite) {
            return b.activateDate - a.activateDate;
        }
        return isAFavorite ? -1 : 1;
    });

    return sortedSites.map((site) => site.id);
});

export const getSites = createSelector(getSitesState, (state: SitesState) => {
    const filteredSites = Object.values(state?.sites).filter((site: Site) => !site.gatewayId.startsWith('16')); // exclude BeeconBox

    return filteredSites.reduce((sites: Record<SiteId, Site>, site: Site) => {
        sites[site.id] = site;
        return sites;
    }, {});
});

export const getSitesList = createSelector(
    getSiteIdList,
    getSites,
    (siteIds: SiteId[], sites: Record<SiteId, Site>) => {
        return siteIds?.map((siteId) => sites?.[siteId]).filter(Boolean) ?? [];
    },
);
