import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { Site, SiteId } from '../../../site-detail/models/Site';
import {
    loadSiteInfo,
    loadSiteOwner,
    removeSite,
    waitForServegoApproval,
} from '../../../site-detail/store/actions/site';
import { SitesActions } from '../actions/sites';

export interface SitesState {
    sites: Record<SiteId, Site>;
    list: SiteId[];
    favorites: SiteId[];
}

export const SITES_INITIAL_STATE: SitesState = {
    sites: {},
    list: [],
    favorites: [],
};

export default createReducer(
    SITES_INITIAL_STATE,

    on(SitesActions.sitesLoaded, (state, { sites }): SitesState => {
        if (!(sites instanceof Array)) {
            return state;
        }

        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };
        let siteIdList: SiteId[] = [...state.list];

        const sitesRecord = sites.reduce((updatedSitesRecord, siteItem: Site) => {
            const newSite = { ...siteItem };

            updatedSitesRecord[newSite.id] = newSite;
            siteIdList = updateSiteList(siteIdList, newSite);

            return updatedSitesRecord;
        }, sitesRecordCopy);

        return { ...state, sites: sitesRecord, list: siteIdList };
    }),

    on(SitesActions.favoritesLoaded, (state, { favorites }): SitesState => {
        if (!(favorites instanceof Array)) {
            return state;
        }

        const siteIdList = favorites.map((site) => site.id);

        return { ...state, favorites: [...siteIdList] };
    }),

    on(loadSiteInfo, (state, { site }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };
        const existingSite = sitesRecordCopy[site.id];
        const newSite = existingSite ? { ...existingSite, ...site } : site;

        sitesRecordCopy[newSite.id] = newSite;

        return { ...state, sites: sitesRecordCopy };
    }),

    on(SitesActions.siteAddedAsFavorite, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };
        let favoritesListCopy: SiteId[] = [...state.favorites];

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], favorite: true };

        favoritesListCopy = updateFavoritesList(favoritesListCopy, sitesRecordCopy[siteId]);

        return { ...state, sites: sitesRecordCopy, favorites: favoritesListCopy };
    }),

    on(SitesActions.siteRemovedFromFavorites, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };
        let favoritesListCopy: SiteId[] = [...state.favorites];

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], favorite: false };

        favoritesListCopy = updateFavoritesList(favoritesListCopy, sitesRecordCopy[siteId]);

        return { ...state, sites: sitesRecordCopy, favorites: favoritesListCopy };
    }),

    on(waitForServegoApproval, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], isServegoAsking: true };

        return { ...state, sites: sitesRecordCopy };
    }),

    on(loadSiteOwner, (state, { siteId, owner }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId] || !owner) {
            return state;
        }

        sitesRecordCopy[siteId] = { ...sitesRecordCopy[siteId], owner };

        return { ...state, sites: sitesRecordCopy };
    }),

    on(removeSite, (state, { siteId }): SitesState => {
        const sitesRecordCopy: Record<SiteId, Site> = { ...state.sites };

        if (!sitesRecordCopy[siteId]) {
            return state;
        }

        delete sitesRecordCopy[siteId];

        const updatedList = state.list.filter((id) => id !== siteId);
        const updatedFavorites = state.favorites.filter((id) => id !== siteId);

        return { ...state, sites: sitesRecordCopy, list: updatedList, favorites: updatedFavorites };
    }),

    on(
        SitesActions.sitesListReset,
        (state): SitesState => ({
            ...state,
            sites: {},
            list: [],
            favorites: [],
        }),
    ),
);

const updateSiteList = (siteIdList: SiteId[], newSite: Site): SiteId[] => {
    if (!siteIdList.includes(newSite.id)) {
        return [...siteIdList, newSite.id];
    }
    return siteIdList;
};

const updateFavoritesList = (favoritesListCopy: SiteId[], newSite: Site): SiteId[] => {
    if (newSite.favorite && !favoritesListCopy.includes(newSite.id)) {
        return [...favoritesListCopy, newSite.id];
    } else if (!newSite.favorite && favoritesListCopy.includes(newSite.id)) {
        return favoritesListCopy.filter((id) => id !== newSite.id);
    }
    return favoritesListCopy;
};

export const getSitesState = createFeatureSelector<SitesState>('sites');
