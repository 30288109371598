import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Site, SiteId } from '../../../site-detail/models/Site';

export const SitesActions = createActionGroup({
    source: 'Sites',
    events: {
        sitesLoaded: props<{ sites: Site[] }>(),
        favoritesLoaded: props<{ favorites: Site[] }>(),
        siteAddedAsFavorite: props<{ siteId: SiteId }>(),
        siteRemovedFromFavorites: props<{ siteId: SiteId }>(),
        sitesListReset: emptyProps(),
    },
});
