import { Injectable } from '@angular/core';

export type DiagItem = { status: 'info' | 'warn' | 'error'; message: string };

export type Diag = {
    cmsGatewayInfo: DiagItem[];
    cmsGatewayStatus: DiagItem[];
    msSiteByGateway: DiagItem[];
    ovkSetup: DiagItem[];
    ovkPartner: DiagItem[];
};

@Injectable()
export class BoxDiagService {
    private diag: Diag;

    private resetDiag() {
        this.diag = {
            cmsGatewayInfo: [],
            cmsGatewayStatus: [],
            msSiteByGateway: [],
            ovkSetup: [],
            ovkPartner: [],
        };
    }

    process(cmsGatewayInfo, cmsGatewayStatus, msSiteByGateway, ovkSetup, ovkPartner): Diag {
        this.resetDiag();

        this.diagCms(cmsGatewayInfo, cmsGatewayStatus);
        this.diagMsSite(msSiteByGateway, ovkSetup);
        this.diagOvk(ovkSetup, msSiteByGateway, ovkPartner);

        return this.diag;
    }

    private diagCms(cmsGatewayInfo, cmsGatewayStatus) {
        if (cmsGatewayInfo?.DATA?.length === 1) {
            this.diag.cmsGatewayInfo.push({
                status: 'info',
                message: 'Installation trouvée',
            });

            if (cmsGatewayStatus.statusCode === 409) {
                this.diag.cmsGatewayStatus.push({
                    status: 'info',
                    message: 'État de pré-validation cohérent',
                });
            } else {
                this.diag.cmsGatewayStatus.push({
                    status: 'error',
                    message: 'État de pré-validation invalide',
                });
            }
        } else if (cmsGatewayInfo?.DATA?.length > 1) {
            this.diag.cmsGatewayInfo.push({
                status: 'error',
                message: 'Plusieurs box trouvées : action à prendre inconnue.',
            });
        } else {
            this.diag.cmsGatewayInfo.push({
                status: 'warn',
                message:
                    'Aucune box trouvée : vérifier la langue et le CRM ID du pro auquel la box devrait être rattachée.',
            });
            if (cmsGatewayStatus.statusCode === 409) {
                this.diag.cmsGatewayStatus.push({
                    status: 'error',
                    message:
                        "État de pré-validation invalide : vérifier que la box n'est pas rattachée à un autre pro ou sur une autre langue.",
                });
            } else {
                this.diag.cmsGatewayStatus.push({
                    status: 'info',
                    message: 'Box disponible pour un rattachement',
                });
            }
        }
    }

    private diagMsSite(msSiteByGateway, ovkSetup) {
        if (msSiteByGateway?.length === 1) {
            this.diag.msSiteByGateway.push({
                status: 'info',
                message: 'Site trouvé',
            });
            if (ovkSetup) {
                if (msSiteByGateway[0].setup_id === ovkSetup.id) {
                    this.diag.msSiteByGateway.push({
                        status: 'info',
                        message: 'Setup ID OVK identique',
                    });
                } else {
                    this.diag.msSiteByGateway.push({
                        status: 'error',
                        message: 'Setup ID OVK différent',
                    });
                }
            }
        } else if (msSiteByGateway?.length > 1) {
            this.diag.msSiteByGateway.push({
                status: 'error',
                message: 'Plusieurs sites trouvés : nettoyage de la base de données nécessaire.',
            });
        } else {
            this.diag.msSiteByGateway.push({
                status: 'warn',
                message: 'Aucune site trouvé',
            });
        }
    }

    private diagOvk(ovkSetup, msSiteByGateway, ovkPartner) {
        if (ovkSetup) {
            this.diag.ovkSetup.push({ status: 'info', message: 'Setup trouvé' });
        } else {
            this.diag.ovkSetup.push({
                status: 'warn',
                message: 'Aucun setup trouvé',
            });
        }

        // TODO evol: make this on each installation/site/setup gateway
        if (msSiteByGateway?.length > 0) {
            if (ovkPartner?.associationType === 'SERVEGO') {
                this.diag.ovkPartner.push({
                    status: 'info',
                    message: 'Abonnement déclaré',
                });
            } else {
                this.diag.ovkPartner.push({
                    status: 'error',
                    message: 'Abonnement manquant : ajout requis pour le suivi des évènements.',
                });
            }
        }
    }
}
