<app-loader [visible]="userEnvLoading"></app-loader>
<div class="header-wrapper">
  <header class="main">
    <div class="left">
      <app-somfypro-logo [url]="somfyProUrl"></app-somfypro-logo>
      <div class="separator"><!--&#x7c;--></div>
      <div class="servego-logo-wrapper seg-cursor-pointer">
        <img class="serv-e-go-logo" src="assets/img/servego_yellow.svg" (click)="onServegoHeaderClick()">
      </div>
    </div>
    <div class="menu-items">
      <a [routerLink]="['/sites']"
         routerLinkActive="active"
      >
          <span class="regular">{{ 'INSTALLATIONS' | translate }}</span>
      </a>
      <a [routerLink]="['/alerts']"
         (click)="analyticService.clickAlertsMenu()"
         routerLinkActive="active"
      >
        <span
          class="regular"
          [matBadgeHidden]="!hasAlerts"
          [matBadge]="numberOfAlerts < 100 ? numberOfAlerts : '99+'"
          matBadgeSize="small"
          matBadgeColor="warn"
        >
          {{ 'ALERTS' | translate }}
        </span>
      </a>
      <a [routerLink]="['/messages']"
         (click)="analyticService.clickMessagesMenu()"
         routerLinkActive="active"
      >
        <span
          class="regular"
          [matBadgeHidden]="!hasMessages"
          [matBadge]="numberOfUnreadMessages < 100 ? numberOfUnreadMessages : '99+'"
          matBadgeSize="small"
          matBadgeColor="warn">
          {{ 'MESSAGES' | translate }}
        </span>
      </a>
      <app-side-nav [userLogin]="userLogin" />
    </div>
  </header>
</div>
<div class="content-wrapper" [ngClass]="{'sizeSet': contentWrapperSizeSet, 'white-background': contentWrapperWhiteBg}">
  <router-outlet></router-outlet>
</div>

<app-toaster></app-toaster>

<app-iadvize></app-iadvize>
