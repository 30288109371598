import { NgClass } from '@angular/common';
import { Component, Input, type OnChanges } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AlertSeverityComponent } from '../../../alerts/components/alert-severity/alert-severity.component';
import { AlertSeverity } from '../../../alerts/models/Alert';
import { User } from '../../../shared/models/User';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { SiteIconComponent } from '../../../site-detail/components/icon/site-icon.component';
import { Site } from '../../../site-detail/models/Site';
import { SiteService } from '../../../site-detail/services/site.service';
import { toShortDate } from '../../../utils/date';
import { SiteFavoriteComponent } from '../site-favorite/site-favorite.component';

@Component({
    selector: 'app-site-item',
    templateUrl: './site-item.component.html',
    styleUrls: ['./site-item.component.scss', '../../site-list.scss'],
    standalone: true,
    imports: [
        RouterLinkActive,
        RouterLink,
        NgClass,
        SiteIconComponent,
        SiteFavoriteComponent,
        MatDivider,
        AlertSeverityComponent,
        MatTooltip,
        TranslateModule,
    ],
})
export class SiteItemComponent implements OnChanges {
    @Input() userLogin: User;
    @Input() site: Site;
    @Input() alertsSeverity: AlertSeverity = null;
    @Input() maxFavoriteReached = false;

    protected tooltip: string;
    protected date: string;

    constructor(
        private siteService: SiteService,
        private analyticsService: AnalyticsService,
    ) {}

    onFirstConnection(site) {
        this.analyticsService.clickBoxThroughInstallationList();
        this.siteService.setConnection(site, this.userLogin.id, '').subscribe();
    }

    ngOnChanges() {
        this.tooltip = this.getTooltip(this.site);
        this.date = toShortDate(this.site.activateDate);
    }

    getTooltip(site: Site): string | null {
        if (!site.isServego) {
            return 'ACTIVATE_SERVEGO_TEXT';
        }

        if (site.isToolSite) {
            return 'TOOL_SITE_DESCRIPTION';
        }

        return null;
    }
}
