import { createSelector } from '@ngrx/store';
import { DeviceId } from '../../../site-detail/models/Device';
import type { SiteId } from '../../../site-detail/models/Site';
import { getUISiteId } from '../../../site-detail/store/selectors/ui';
import { Alert, ALERT_SEVERITY, ALERT_STATUS, AlertSeverity } from '../../models/Alert';
import { sortAlerts } from '../../utils/sortAlerts';
import { getAlertsState } from '../reducers/alerts';

export const getAlerts = createSelector(getAlertsState, ({ alerts }) => Object.values(alerts));

export const getPendingAlerts = createSelector(getAlerts, (alerts) =>
    alerts.filter((alert) => alert.status !== ALERT_STATUS.RESOLVED),
);

export const getAlertById = (alertId) => createSelector(getAlertsState, ({ alerts }): Alert => alerts[alertId]);

export const getSortedPendingAlerts = createSelector(getPendingAlerts, (alerts): Alert[] => {
    return [...alerts].sort((a, b) => {
        const dayA = new Date(a.date).setHours(0, 0, 0, 0);
        const dayB = new Date(b.date).setHours(0, 0, 0, 0);

        if (dayA !== dayB) return dayB - dayA;

        if (a.id_site !== b.id_site) return a.id_site.localeCompare(b.id_site);

        if (a.status !== b.status) {
            if (a.status === ALERT_STATUS.NEW) return -1;
            if (b.status === ALERT_STATUS.NEW) return 1;
        }

        if (a.severity !== b.severity) {
            return b.severity - a.severity;
        }

        return b.date - a.date;
    });
});

export const getAlertsSeverityBySites = createSelector(getPendingAlerts, (alerts): Record<SiteId, AlertSeverity> => {
    return alerts.reduce<Record<SiteId, AlertSeverity>>((acc, alert) => {
        acc[alert.id_site] = Math.max(acc[alert.id_site] ?? ALERT_SEVERITY.NORMAL, alert.severity) as AlertSeverity;
        return acc;
    }, {});
});

export const getAlertsLoading = createSelector(getAlertsState, (state) => state?.alertsLoading);

export const getSelectedSiteAlerts = createSelector(getPendingAlerts, getUISiteId, (alerts, siteId): Alert[] =>
    alerts.filter(({ id_site }) => id_site === siteId),
);

export const getSiteAlerts = (siteId: SiteId) =>
    createSelector(getPendingAlerts, (alerts): Alert[] => alerts.filter(({ id_site }) => id_site === siteId));

export const getDeviceAlerts = (deviceId: DeviceId) =>
    createSelector(getSelectedSiteAlerts, (alerts): Alert[] =>
        sortAlerts(alerts.filter((alert) => alert.id_device === deviceId)),
    );
