import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { InterventionsApi } from '../../../interventions/store/api/interventions';
import { AppState } from '../../../shared/store/app-state';
import { getSites } from '../../../site-list/store/selectors/site-list';
import { Site, SiteId } from '../../models/Site';
import { DeviceService } from '../../services/device.service';
import { SiteService } from '../../services/site.service';
import { SiteDevicesActions } from '../actions/devices';
import { loadSiteInfo } from '../actions/site';

@Injectable()
export class SiteDetailApi {
    constructor(
        private store: Store<AppState>,
        private siteService: SiteService,
        private deviceService: DeviceService,
        private interventionsApi: InterventionsApi,
    ) {}

    loadSite(siteId: string, withDeviceSynchro = false): Observable<any> {
        return this.loadSiteInfo(siteId).pipe(
            mergeMap((site) => {
                return forkJoin([
                    this.loadSiteDevices(site.id, withDeviceSynchro),
                    this.interventionsApi.loadSiteInterventions(site.id),
                ]);
            }),
        );
    }

    loadSiteInfo(siteId: string): Observable<Site> {
        let sites: Record<SiteId, Site>;
        this.store
            .select(getSites)
            .pipe(take(1))
            .subscribe((value) => (sites = value));

        const siteFound: Site = sites[siteId];
        if (siteFound) {
            const siteFoundCopy = Object.assign({}, siteFound);
            return of(siteFoundCopy);
        } else {
            return this.siteService.getSite(siteId).pipe(
                map((site) => {
                    this.store.dispatch(loadSiteInfo({ site }));
                    return site;
                }),
            );
        }
    }

    loadSiteDevices(siteId: string, withSynchro = false): Observable<any> {
        const keepMainDevices = (device) => {
            return device.deviceurl.indexOf('#') === -1 || device.deviceurl.substr(-2) === '#1';
        };
        return this.deviceService.getSiteDevices(siteId, withSynchro).pipe(
            map((rawDevices) => {
                const devices = rawDevices.filter(keepMainDevices);
                this.store.dispatch(SiteDevicesActions.devicesLoaded({ siteId, devices }));
                return devices;
            }),
        );
    }
}
