import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { userLanguageChanged } from '../../../shared/store/actions/user-env';
import { AppState } from '../../../shared/store/app-state';

@Injectable()
export class LangEffects {
    storeUserLanguage$ = createEffect(() =>
        this.translateService.onLangChange.pipe(
            map(({ lang }) => lang),
            startWith(this.translateService.currentLang),
            map((language) => userLanguageChanged({ language })),
        ),
    );

    constructor(
        private store: Store<AppState>,
        private translateService: TranslateService,
    ) {}
}
